import React, { useEffect, useState } from "react";
import { Circle, GoogleMap, LoadScript } from "@react-google-maps/api";
import { useGetAllRestaurantsQuery } from "services/restaurantsApi";
import { PositionMarker } from "features/Map/PositionMarker";
import { Panel } from "features/Panel/Panel";
import { useGetAllChefsMutation } from "services/usersApi";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { MarkerClusterer } from "@react-google-maps/api";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import ClearIcon from "@mui/icons-material/Clear";
import Baldo from "../../assets/2023/baldo.png";
import { useTranslation } from "react-i18next";
import { Geolocation } from "@capacitor/geolocation";
import myLocationIcon from "assets/location-icon.png";

export function Map(props) {
  const navigate = useNavigate();
  const [mylocation, setMyLocation] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const userAuth = useAuthUser();
  const restaurants = useGetAllRestaurantsQuery().data?.restaurants;
  const [users, setUsers] = useState([]);
  const [getChefs, getChefsResults] = useGetAllChefsMutation();
  const [loadingMyLocation, setLoadingMyLocation] = useState(false);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const url = useResolvedPath().pathname;
  const authUser = useAuthUser();

  const [radius, setRadius] = useState(2);
  const {
    sidebarIsOpened,
    setSidebarOpen,
    itemSelected,
    setItem,
    mapLocation,
    setMapLocation,
    zoom,
    setZoom,
  } = props;

  useEffect(() => {
    if (authUser() && authUser().active) getChefs();
    Geolocation.checkPermissions().then(async (result) => {
      if (result.location === "denied") {
        Geolocation.requestPermissions();
      } else {
        const coordinates = await Geolocation.getCurrentPosition();

        setMyLocation([
          coordinates.coords.latitude,
          coordinates.coords.longitude,
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (getChefsResults.isSuccess) setUsers(getChefsResults.data?.users);
    if (getChefsResults.isError && authUser() && authUser().active) getChefs();
  }, [getChefsResults]);

  function handleZoomChanged() {
    const location = this.getCenter();
    setMapLocation([location.lat(), location.lng()]);
    setZoom(this.getZoom());
  }

  useEffect(() => {
    if (id) {
      if (!window.location.pathname?.includes("chef")) {
        const a = restaurants?.filter?.(
          (item) => parseInt(id) === item?.id
        )?.[0];
        setItem(a);
        setSidebarOpen(true);
      } else {
        const a = users?.filter?.((item) => parseInt(id) === item?.id)?.[0];
        setItem(a);
        setSidebarOpen(true);
      }
    }
  }, [restaurants, id, users]);

  const blueCircle = {
    fillOpacity: 0.4,
    strokeOpacity: 0.4,
    strokeWeight: 1,
    zIndex: 1,
    fillColor: `#4285F4`,
    strokeColor: `rgb(255,255,255)`,
    border: "2px solid #fff",
    scale: 10,
  };

  const blueDot = {
    fillColor: `#4285F4`,
    fillOpacity: 1,
    scale: 10,
    strokeColor: `rgb(255,255,255)`,
    strokeWeight: 2,
  };

  useEffect(() => {
    if (zoom <= 3) setRadius(131072);
    if (zoom === 4) setRadius(131072);
    if (zoom === 5) setRadius(65536);
    if (zoom === 6) setRadius(32768);
    if (zoom === 7) setRadius(16384);
    if (zoom === 8) setRadius(8192);
    if (zoom === 9) setRadius(4096);
    if (zoom === 10) setRadius(2048);
    if (zoom === 11) setRadius(1024);
    if (zoom === 12) setRadius(512);
    if (zoom === 13) setRadius(256);
    if (zoom === 14) setRadius(128);
    if (zoom === 15) setRadius(64);
    if (zoom === 16) setRadius(32);
    if (zoom === 17) setRadius(16);
    if (zoom === 18) setRadius(8);
    if (zoom === 19) setRadius(4);
    if (zoom === 20) setRadius(2);

    console.log(zoom);
  }, [zoom]);

  return (
    <Box
      sx={{
        mt: { xs: "15vh", sm: "80px" },
        height: { xs: "63vh", sm: "74.5vh", md: "77vh", xl: "76vh" },
        "@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)":
          {
            height: "70vh",
          },
        "@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)":
          {
            height: "70vh",
          },
      }}
    >
      <LoadScript googleMapsApiKey="AIzaSyCtKjiLmtKQ-R79xiAllwqrv0KK2sjtpF8">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: mapLocation[0], lng: mapLocation[1] }}
          zoom={zoom}
          options={{
            gestureHandling: "greedy",
            fullscreenControl: false,
            minZoom: 3,
            styles: [
              {
                elementType: "labels",
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                elementType: "labels",
                featureType: "poi.park",
                stylers: [{ visibility: "off" }],
              },
              {
                elementType: "labels",
                featureType: "poi.place_of_worship",
                stylers: [{ visibility: "off" }],
              },
              {
                elementType: "labels",
                featureType: "poi.school",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          onZoomChanged={handleZoomChanged}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "1%",
              top: "max(0.5%, 17px)",
            }}
            onClick={async () => {
              Geolocation.checkPermissions().then(async (result) => {
                if (result.location === "denied") {
                  Geolocation.requestPermissions();
                } else {
                  setLoadingMyLocation(true);
                  const coordinates = await Geolocation.getCurrentPosition();
                  setMapLocation([
                    coordinates.coords.latitude,
                    coordinates.coords.longitude,
                  ]);
                  setMyLocation([
                    coordinates.coords.latitude,
                    coordinates.coords.longitude,
                  ]);
                  setZoom(18);
                  setLoadingMyLocation(false);
                }
              });
            }}
          >
            <img
              src={myLocationIcon}
              alt=""
              srcSet={myLocationIcon}
              style={{ width: "42px", height: "42px" }}
            />
          </IconButton>
          {mylocation !== null && (
            <>
              <Circle
                center={{ lat: mylocation[0], lng: mylocation[1] }}
                visible={true}
                options={blueDot}
                radius={radius}
              />
              <Circle
                center={{ lat: mylocation[0], lng: mylocation[1] }}
                visible={true}
                options={blueCircle}
                radius={radius * 2}
              />
            </>
          )}
          <Panel
            data={itemSelected}
            sidebarIsOpened={sidebarIsOpened}
            setSidebarOpen={setSidebarOpen}
            map={mapLocation}
            setMap={setMapLocation}
            setZoom={() => setZoom(18)}
          />
          <MarkerClusterer
            enableRetinaIcons
            averageCenter
            onClick={(e) => {
              setMapLocation([e.getCenter().lat(), e.getCenter().lng()]);
            }}
            minimumClusterSize={7}
            maxZoom={22}
            styles={[
              {
                url: "https://mytraveldinner.s3.amazonaws.com/m5.png",
                height: 55,
                width: 55,
                fontFamily: "Lato",
                textColor: "#FFF",
              },
            ]}
          >
            {url === "/"
              ? (clusterer) =>
                  restaurants?.map((restaurant) => {
                    if (
                      restaurant.geolocation.latitude |
                      restaurant.geolocation.longitude
                    ) {
                      return (
                        <PositionMarker
                          lat={restaurant?.geolocation?.latitude}
                          lng={restaurant?.geolocation?.longitude}
                          restaurant={restaurant}
                          key={restaurant.id}
                          setRestaurant={setItem}
                          showPanel={setSidebarOpen}
                          map={mapLocation}
                          setMap={setMapLocation}
                          clusterer={clusterer}
                          active={userAuth() ? userAuth().active : false}
                          showSignUpPopUp={setOpen}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
              : (clusterer) =>
                  users?.map((user) => {
                    return user?.chefs?.map((restaurant) => {
                      let lat = restaurant?.restaurant?.geolocation?.latitude;
                      let lng = restaurant?.restaurant?.geolocation?.longitude;
                      if (lat && lng) {
                        return (
                          <PositionMarker
                            lat={lat}
                            lng={lng}
                            chef={user}
                            restaurant={restaurant?.restaurant}
                            key={restaurant?.restaurant?.id}
                            setRestaurant={setItem}
                            showPanel={setSidebarOpen}
                            map={mapLocation}
                            setMap={setMapLocation}
                            clusterer={clusterer}
                            active={userAuth().active}
                            showSignUpPopUp={setOpen}
                          />
                        );
                      } else {
                        return null;
                      }
                    });
                  })}
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box>
          <Grid
            container
            sx={{
              display: { md: "flex", xs: "none" },
              width: "75%",
              maxWidth: "75%",
              minHeight: "50%",
              backgroundColor: "#212121",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
            }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                border: "1px solid #fff",
              }}
            >
              <ClearIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Grid
              item
              xs={7}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img src="https://placehold.co/600x600" alt="" />
            </Grid>
            <Grid
              item
              xs={5}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={11}
                container
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <Typography variant="h4" color="#fff">
                  {t("newMemberMessage")}
                </Typography>
                <Typography variant="subtitle1" color="#fff">
                  {t("popUpMessage")}
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap={6}
                >
                  <Button
                    sx={{ backgroundColor: "#fff" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    {t("signIn")}
                  </Button>
                  <Button
                    sx={{ backgroundColor: "#1396FF", color: "#fff" }}
                    onClick={() => navigate("/auth/signup")}
                  >
                    {t("signUp")}
                  </Button>
                  <img src={Baldo} srcSet={Baldo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: { md: "none", xs: "flex" },
              width: "100vw",
              maxWidth: "100vw",
              minHeight: "100vh",
              height: "100vh",
              backgroundColor: "#212121",
            }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                border: "1px solid #fff",
              }}
            >
              <ClearIcon sx={{ color: "#fff" }} />
            </IconButton>

            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={8}
                container
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <Typography variant="h4" color="#fff">
                  {t("homePopUpTitle")}
                </Typography>
                <Typography variant="subtitle1" color="#fff">
                  {t("homePopUpDescription")}
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap={6}
                >
                  <Button
                    sx={{ backgroundColor: "#fff" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    Sign In
                  </Button>
                  <Button
                    sx={{ backgroundColor: "#1396FF", color: "#fff" }}
                    onClick={() => navigate("/auth/signup")}
                  >
                    Sign Up
                  </Button>
                  <img src={Baldo} srcSet={Baldo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {loadingMyLocation && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </Box>
  );
}
